@import "./Colors.scss";


.navbar {
    z-index: 6 !important;
    background-color: white;
    border-bottom: 1px solid $lightGrey;
    height: 61.5px;
    position: relative;

    .notification-component {
        position: relative;
    
        .notification-counter {
            position: absolute;
            left: 100%;
            top: 100%;
            transform: translate(-50%, -50%);
            width:25px;
            height: 25px;
            background-color: rgb(59,134,255);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            border-radius: 50%;
    
          p {
              color: white;
              margin: 0 !important;
          }
        }
    }

    .navbar-brand {
       
        img {
            width: 150px;
        }
    }


    .navbar-nav {
        background-color: white;

       .nav-link {
           color: black !important;
           
       }
       
    }
   

    .navbar-toggler {
        color: $darkGrey;
    .navbar-toggler-icon {
        color: $darkGrey !important;
        border-color: $darkGrey !important;
    }
}
}