@import "./Colors.scss";

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.flex-column-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items:center;
    flex-wrap: wrap;
}


.flex-left {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.flex-left-nowrap {
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}