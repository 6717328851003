@import "./Animations.scss";
@import "./Boilerpolate.scss";
@import "./Buttons.scss";
@import "./Colors.scss";
@import "./FlexTools.scss";
@import "./Loaders.scss";
@import "./Navbar.scss";
@import "./Sections.scss";



@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";


.btn {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline:none !important;
        box-shadow: none !important;
    }
}

.custom-select {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline:none !important;
        box-shadow: none !important;
    }
}

.form-control {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        outline: none !important;
        box-shadow: none !important;
    }

    &:active {
        outline: none !important;
        box-shadow: none !important;
    }

    &:focus {
        outline:none !important;
        box-shadow: none !important;
    }
}