@import "./Colors.scss";

.btn-custom-1 {
    background-color: $primaryColor;
    color: white;
    border-radius: 3px;
    padding: 10px;
    border: 1.5px solid $primaryColor;
    transition: 0.3s;

    &:hover {
        background-color: transparent;
        color:$primaryColor;


        i {
            color: $primaryColor;
        }
    }

    i {
        color: white;
    }
}


.btn-custom-3 {
    background-color: rgb(5, 196, 107);
    color: white;
    border-radius: 3px;
    padding: 10px;
    border: 1.5px solid rgb(5, 196, 107);
    transition: 0.3s;

    &:hover {
        background-color: transparent;
        color:rgb(5, 196, 107);
    }
}


.btn-custom-4 {
    background-color: rgb(59, 134, 255);
    color: white;
    border-radius: 3px;
    padding: 10px;
    border: 1.5px solid rgb(59, 134, 255);
    transition: 0.3s;

    &:hover {
        background-color: transparent;
        color:rgb(59, 134, 255);
    }
}



.btn-disabled {
    background-color: rgb(196, 194, 194);
    color: white;
    border-radius: 3px;
    padding: 10px;

    &:hover {
        background-color:rgb(196, 194, 194);
        color:white
    }

}