@import "./Colors.scss";

@media print {
    .hide-when-printing {
        display: none;
    }
}
.payment-info {
    padding: 10px;
    border: 1.5px solid dodgerblue;
    background-color: white;

    .total {
        font-size: 40px;
    }

    .bank-details {
        border: 3px solid rgb(22,160,133);
        padding: 10px;
    }
}

.paymnt-info-container {
    padding: 40px;
}

.contract {
    padding: 40px;

    .text-1 {
        font-size: 20px;
        color:rgb(5, 196, 107);
    }

    .text-3 {
        font-size: 25px;
        color: rgb(7,112,204);
    }

    .text-4 {
        font-size: 20px;
        color: $primaryColor;
    }

    .text-2 {
        font-size: 18px;
    }

    .dashed {
        border-bottom: 2px dashed $lightGrey !important;
        background-color: white;
        border-left: none !important;
        border-top: none !important;
        border-right: none !important;
    }
}

.auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .auth-panel {
        min-width: 30%;
        .logo {
            width: 250px;
            height: 50px;
        }

        .form-group {
            width: 100%;

            .form-control {
                border:1px solid $borderColor;
                border-radius: 3px;
                outline: none !important;
                box-shadow:none !important;
                background-color: $superLightGrey;
                padding: 22px !important;
            }
        }
    }
}


.messenger {
    padding: 20px;
    background-color: rgb(245,245,245);

    

    .tab {
        cursor: pointer;
        &.tab-1 {
            min-width: 150px;
            padding: 10px;
            background-color: rgb(231,239,249);

            p {
                color: rgb(30, 91, 210);
            }
            
        }

        &.tab-2 {
            min-width: 150px;
            padding: 10px;
            background-color: rgb(245, 242, 225);

        }

        p {
            text-align: center;
            color: rgb(119,119,119);
        }
    }

    .notification-element {
        background-color: $lightGrey;
        padding: 10px;
        cursor: pointer;
    }
    
    

    .pending-payments-element {
        padding: 10px;
        color: white;
        background-color: rgb(255,99,72);
    }


    .main-columns {
        height: 100%;
    }

    .left-section {
        max-height: 100%;
        overflow: auto;
        .input-group {
            border:1.5px solid $darkGrey;
            .form-control {
                border:none !important;
                outline:none !important;
                box-shadow: none !important;

                &:focus {
                    outline:none !important;
                box-shadow: none !important;
                }
            }

            .input-group-append {
                background-color: white;
            }
        }


        .chat-element-2 {
            background-color: rgb(231,239,249);
            padding: 15px;
            cursor: pointer;
            transition: 0.3s;
            position: relative;
            min-height: 158px;

            &.active {
                background-color: rgb(59,134,255);
                color: white;
            }

            .image-circle {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: white;
            }

           

        }

        .chat-element {
            background-color: rgb(245,242,225);
            padding: 15px;
            cursor: pointer;
            transition: 0.3s;
            position: relative;
            min-height: 158px;

            &.active {
                background-color: rgb(226, 223, 208);
            }

            .image-circle {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: white;
            }

            .unread {
                position: absolute;
                right: 5px;
                bottom: 5px;
                width: 30px;
                height: 30px;
                background-color: rgb(59,134,255);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                animation-name:attention;
                animation-duration: 0.5s;
                animation-iteration-count: infinite;

                p {
                    color: white;
                    
                }
            }

            @keyframes attention {
                0%{transform: scale(1);}
                50%{transform: scale(1.1);}
                100%{transform: scale(1);}
            }

        }
    }

    .middle-section {

        .btn-iva {
            border-color: $primaryColor !important;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            background-color: white !important;
            i {
                color: $darkGrey;
            }
        }

        .form-iva {
            border-color: $primaryColor !important;
            padding: 20px !important;
        }

        .text-1 {
            font-size: 20px;
            color:rgb(5, 196, 107);
        }
        .text-2 {
            font-size: 18px;
        }
        .messages-box {
            border:1.5px solid $darkGrey;
            background-color: white;
            position: relative;


            .messages-box-container {
                position: absolute;
                padding: 10px;
                bottom:0 !important;
                left: 0 !important;
                overflow: auto;
                width: 100%;
                max-height: 100%;

                .message-item {
                    
                    &.system {
                        
                        color: black;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        
                        

                        .message-item-box {
                            padding: 20px;
                            background-color:rgb(237,240, 245);
                            border-radius: 10px;
                            max-width: 100%;
                            width: 100%;
                            position: relative;

                            .time {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                color: $darkGrey;
                                font-size: 12px;
                            }
                            
                        }
                    }
                    &.owned {
                        
                        color: white;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        
                        

                        .message-item-image {
                            width: 40px;
                            height: 40px;
                            background-color: rgb(59,134,255);
                            border-radius: 50%;
                        }

                        .message-item-box {
                            padding: 20px;
                            background-color: rgb(59,134,255);
                            border-radius: 10px;
                            border-bottom-right-radius: 0 !important;
                            max-width: 80%;
                            position: relative;

                            .time {
                                position: absolute;
                                top: 100%;
                                right: 0;
                                color: $darkGrey;
                                font-size: 12px;
                            }
                        }
                    }

                    &.other {
                 
                        display: flex;
                        justify-content: left;
                        

                        .message-item-image {
                            width: 40px;
                            height: 40px;
                            background-color: rgb(237,240, 245);
                            border-radius: 50%;
                        }

                        .message-item-box {
                            padding: 20px;
                            background-color: rgb(237,240, 245);
                            border-radius: 10px;
                            border-top-left-radius:0 !important;
                            max-width: 80%;
                            position: relative;

                            .time {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                color: $darkGrey;
                                font-size: 12px;
                            }
                        }
                    }

                    
                }
            }
        }

        .input-message-container {
            .btn-file {
                overflow: hidden !important;
                .custom-file {
                    position: absolute;
                    top:0;
                    left: 0;
                    height: 100% !important;
                    max-width: 100%;
                    width: 100%;
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                    background-color: transparent !important; 
                    cursor: pointer;
                    max-height: 100%;
            
                    .custom-file-input {
                        height: 100%;
                        width: 100%;
                        max-width: 100%;
                        border: none !important;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
                    }
                    .custom-file-label {
                        height:100% !important;
                        border: none !important;
                        max-height: 100%;
                        max-width: 100%;
                        background-color: transparent !important;
                        cursor: pointer;
                        outline: none !important;
                    box-shadow: none !important;
            
                        &:after {
                            content:"";
                            background-color: transparent;
                            border: none !important;
                            cursor: pointer;
                            outline: none !important;
                    box-shadow: none !important;
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                        }
                    }
                }
            }
        }

        .input-group {
            .form-control {
                border-radius: 0;
                box-shadow: none !important;
                outline: none !important;
                border-color: $primaryColor;

                &:focus {
                    box-shadow: 0 !important;
                    outline: none !important;
                }
            }

            .input-group-append{
                border:1.5px solid $darkGrey;
                height: 38px;
                margin-left: 10px;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;

                .btn {
                    padding-top: 5px !important;
                    padding-bottom: 5px !important;
                }
            }
        }
    }

    .right-section {
        overflow: auto !important;
        .right-section-content {
            overflow: auto;
            height:  auto !important;
            max-height: auto !important;
        }

        .btn-iva {
            border-color: $primaryColor !important;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            background-color: white !important;
            i {
                color: $darkGrey;
            }
        }

        .form-iva {
            border-color: $primaryColor !important;
            padding: 20px !important;
        }

        .text-1 {
            font-size: 20px;
            color:rgb(5, 196, 107);
        }
        .text-2 {
            font-size: 18px;
        }
    }
}





.modal-wrapper {
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 40px;
    background-color: rgba(0,0,0,0.4);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;

    hr {
        width: 100% !important;
        background-color: $primaryColor;
    }
    

    .selectable-block {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 3px dashed darkgray;
    }

    .form-iva {
        border-color: $primaryColor;
    }

    .btn-close-modal {
        position: fixed;
        top:20px;
        right: 20px;
        background-color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        z-index: 13;
        display: flex;
        justify-content: center;
        align-items: center;

       

        animation-name: show-in-late;
        animation-iteration-count: 1;
        animation-duration: 1s;

        @keyframes show-in-late {
            0%{opacity: 0;}
            100%{opacity: 1;}
        }
         i{
             color:$darkGrey !important;
             font-size: 30px;
         }

         &:active {
             border: none !important;
             outline: none !important;
         }

         &:hover {
             border: none !important;
             outline: none !important;
         }
    }
   

     .modal-content  {
         z-index: 12;
         width: 80%;
         overflow: auto;
         background-color: white;
         padding: 40px;
         height: 90%;
         
        .custom-select {
            &:focus {
                border-color: $primaryColor;
            }

            &:active {
                border-color: $primaryColor;
            }
        }

        .DayPicker-Day--selected {
            background-color: $primaryColor;

            &:hover {
                background-color: $primaryColor;
            }
        }

        .text-1 {
            font-size: 20px;
            color:rgb(5, 196, 107);
        }

        .text-2 {
            font-size: 18px;
        }


        .btn-iva {
            border-color: $primaryColor !important;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            background-color: white !important;
            i {
                color: $darkGrey;
            }
        }
     }

    }



@media screen and (max-width:767px){

    .modal-wrapper{

        padding: 0 !important;

    .modal-content {
        width: 100% !important;
        height: 100% !important;
    }
}
    .messenger {
        padding: 0 !important;


        
        .left-section {
            background-color: white;
            padding: 10px !important;
        }

        .right-section {
            background-color: white;
            padding: 10px !important;
            

            .right-section-content {
                overflow: auto;
                height:  auto !important;
                max-height: auto !important;
            }
        }

        .middle-section {

            .driver-info {
                padding: 10px;
                max-height: 100px !important;
                overflow: auto !important;
            }
            .messages-box {
                border: none !important;
            }

            .input-message-container {
                background-color: white;
                padding: 10px;
                border-top: 1.5px solid $lightGrey;

                .btn-file {
                    .custom-file {
                        position: absolute;
                        top:0;
                        left: 0;
                        height: 100px !important;
                        border: none !important;
                        outline: none !important;
                        box-shadow: none !important;
                        background-color: transparent !important; 
                        cursor: pointer;
                
                        .custom-file-input {
                            height: 100%;
                            border: none !important;
                            background-color: transparent !important;
                            cursor: pointer;
                            outline: none !important;
                        box-shadow: none !important;
                        }
                        .custom-file-label {
                            height:100% !important;
                            border: none !important;
                            background-color: transparent !important;
                            cursor: pointer;
                            outline: none !important;
                        box-shadow: none !important;
                
                            &:after {
                                content:"";
                                background-color: transparent;
                                border: none !important;
                                cursor: pointer;
                                outline: none !important;
                        box-shadow: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}